import { createSelector } from 'reselect'
import _ from 'lodash'
import { parse } from 'query-string'
import { checkPermission, getModulePermissions } from './user/selectors'

export const initsSelector = (state) => state.inits
export const loadedInitSelector = createSelector(initsSelector, (inits) => inits.loadedInit)
export const settingsSelector = (state) => state.settings
export const mobileViewSelector = createSelector(
  settingsSelector,
  (settings) => settings.isMobileView,
)
const selectFinance = (state) => state.finance
export const tabViewSelector = createSelector(settingsSelector, (settings) => settings.isTabView)
export const warehouseSelector = (state) => state.finance.warehouse
export const warehousesSelector = createSelector(
  warehouseSelector,
  (warehouse) => warehouse.warehouses,
)
export const warehousesHasRoleSelector = createSelector(
  warehouseSelector,
  (warehouse) => warehouse.warehouses_has_role,
)

export const taxesSelector = createSelector(
  (state) => state.finance.tax,
  (tax) => tax.taxes,
)
export const activeTaxesSelector = createSelector(taxesSelector, (taxes) =>
  taxes.filter((tax) => tax.is_active),
)

export const tutorialsSelector = (state) => state.tutorials

export const optionsSelector = createSelector(
  (state) => state.options,
  (options) => options.options,
)
export const shippingCompSelector = createSelector(selectFinance, (finance) => finance.shippingComp)
export const shippingCompsSelector = createSelector(
  shippingCompSelector,
  (shippingComp) => shippingComp.shippingComps,
)

export const productCategoriesSelector = createSelector(
  selectFinance,
  (finance) => finance.productCategories,
)
export const translationSelector = (state) => state.translation
export const titleSelector = createSelector(
  translationSelector,
  (__, name) => name,
  (translation, name) => (translation.menu ? translation.menu[name] : ''),
)
export const reportTitleSelector = createSelector(
  translationSelector,
  (__, name) => name,
  (translation, name) => (translation.reportmenu ? translation.reportmenu[name] : ''),
)
export const termTitleSelector = createSelector(
  translationSelector,
  (translation) => translation.term,
)
export const financeConfigSelector = createSelector(selectFinance, (finance) => finance.config)
export const bankTransStatusSelector = createSelector(
  financeConfigSelector,
  (config) => config.bank_tran_status,
)
export const contactTypeSelector = createSelector(
  financeConfigSelector,
  (config) => config.contact_type,
)
export const contactSelector = createSelector(financeConfigSelector, (config) => config.contact)
export const desktopLayoutSelector = createSelector(
  [mobileViewSelector, tabViewSelector],
  (isMobileView, isTabView) => !isMobileView && !isTabView,
)

export const auditModalSelector = createSelector(selectFinance, (finance) => finance.auditModal)
export const accountTransReportSelector = createSelector(
  selectFinance,
  (finance) => finance.accountTransactionReport,
)
export const routerSelector = (state) => state.router
export const routerMatchSelector = createSelector(routerSelector, (router) => router.match)
export const locationSelector = createSelector(routerSelector, (router) => router.location)
export const parseLocationSearchSelector = createSelector(locationSelector, (location) =>
  parse(location.search),
)
export const termSelector = createSelector(selectFinance, (finance) => finance.expenseTerm)
export const findTermSelector = createSelector(
  termSelector,
  (__, id) => id,
  (terms, id) => terms.expenseTerms.find((tax) => tax.id === id),
)
export const activeTermsSelector = createSelector(termSelector, (terms) =>
  terms.expenseTerms.filter((term) => term.is_active),
)
export const countActiveTermSelector = createSelector(activeTermsSelector, (terms) => terms.length)
export const canDeletedTermsSelector = createSelector(
  termSelector,
  (term) => term.canDeletedExpenseTerms,
)
export const firstTermSelector = createSelector(activeTermsSelector, (terms) => _.first(terms))
export const firstWarehouseSelector = createSelector(warehousesHasRoleSelector, (warehouses) => {
  const unArchiveWarehouses = (warehouses || []).filter((row) => row.is_archive !== 1)
  const firstWarehouse = _.first(unArchiveWarehouses)
  if (!_.isEmpty(firstWarehouse)) {
    return firstWarehouse
  }
  return {}
})
export const firstWarehouseWithRoleSelector = createSelector(
  warehousesHasRoleSelector,
  firstWarehouseSelector,
  (warehouses, firstWarehouseActive) => {
    const unArchiveWarehouses = (warehouses || []).filter((row) => {
      return row.is_archive !== 1 && _.isEmpty(row.contact_groups)
    })
    if (unArchiveWarehouses.length <= 1) {
      return firstWarehouseActive
    }
    const firstWarehouse = _.first(unArchiveWarehouses)
    if (!_.isEmpty(firstWarehouse)) {
      return firstWarehouse
    }
    return {}
  },
)
export const unitsSelector = createSelector(selectFinance, (finance) => finance.unit.units)
export const broadcastSelector = (state) => state.broadcast
export const showNPSSelector = (state) => state.show_nps
export const billingSelector = (state) => state.billing

export const billingPosSelector = (state) => state.billingPos

export const userSelector = (state) => state.user
export const loginFormSelector = (state) => state.loginForm
const selectCompanies = (state) => state.companies
export const companiesSelector = createSelector(selectCompanies, (companies) => companies.companies)
export const selectedEndpointSelector = createSelector(
  selectCompanies,
  (companies) => companies.selectedEndpoint,
)
export const hiddenFeatureSelector = createSelector(
  billingSelector,
  (billing) => billing.hidden_feature,
)
export const contactGroupsSelector = createSelector(
  selectFinance,
  (finance) => finance.contactGroups,
)
export const defaultMessageSelector = (state) => state.defaultMessage
export const customColumnSelector = (state) => state.customColumn
export const hrisSelector = (state) => state.show_hr_addon
export const multiCurrencySelector = (state) => state.multiCurrency
export const contactIdCardTypeSelector = createSelector(
  selectFinance,
  (finance) => finance.idCardTypes,
)
export const optionSelector = createSelector(optionsSelector, (options) => options.option)
export const darkModeSelector = createSelector(optionsSelector, (options) => options.dark_mode)
export const companySelector = (state) => state.companies
export const bankAccountsSelector = createSelector(
  selectFinance,
  (finance) => finance.bankAccounts || [],
)

export const tempTransKeySelector = createSelector(
  parseLocationSearchSelector,
  (searchs) => searchs?.tempTransKey || '',
)
export const websiteIdSelector = createSelector(
  selectCompanies,
  (companies) => companies.website_id,
)
export const companyLogoSelector = createSelector(
  optionsSelector,
  (options) => options.property_logo,
)
export const companyDetailsSelector = createSelector(optionsSelector, (options) => {
  const detail = {
    property_name: options.property_name,
    property_email: options.property_email,
    property_phone: options.property_phone,
    property_address: options.property_address,
    property_address_delivery: options.property_address_delivery,
    property_country_id: options.property_country_id,
    property_npwp: options.property_npwp,
  }
  return detail
})

export const productCategoriesHasRolesSelector = createSelector(
  selectFinance,
  (finance) => finance.product_categories_has_role,
)
export const financeApprovalSelector = createSelector(
  selectFinance,
  (finance) => finance.financeApproval,
)
export const paymentConnectProvidersSelector = createSelector(
  selectFinance,
  (finance) => finance.paymentConnectProviders,
)
export const maintenanceSelector = (state) => state.maintenance
export const maxCompaniesSelector = (state) => state.max_companies
export const translationReportmenuSelector = createSelector(
  translationSelector,
  (translation) => translation.reportmenu,
)

export const accountCategoriesSelector = createSelector(
  selectFinance,
  (finance) => finance.accountCategories,
)

export const cashBankAccountCategoriesSelector = createSelector(
  accountCategoriesSelector,
  (accountCategories) => {
    const CASH_BANK = 1
    const CREDIT_CARD = 17
    return accountCategories.filter((row) => _.includes([CASH_BANK, CREDIT_CARD], row.id))
  },
)

export const isMultiCurrencySelector = createSelector(
  optionsSelector,
  (options) => options.multi_currency === 1,
)
const selectCurrencies = (state) => state.multiCurrency.currencies
export const baseMultiCurrencySelector = createSelector(
  [selectCurrencies, optionsSelector],
  (currencies, options) => {
    if (!Array.isArray(currencies) && options.multi_currency !== 1) {
      return {}
    }
    const findCurrency = currencies.find((item) => item.id === options.property_currency_id)
    return findCurrency || {}
  },
)

export const transactionFeesSelector = createSelector(
  selectFinance,
  (finance) => finance.fees || [],
)
export const makeTransactionFeeFilteredResults = () =>
  createSelector([transactionFeesSelector], (searchResults) => (search) => {
    return searchResults.filter((result) =>
      result.name?.toLowerCase()?.includes(search.toLowerCase()),
    )
  })

export const sortByCashBankSelector = createSelector(
  optionsSelector,
  (options) => options.bank_account_sort_by,
)
export const dashboardSelector = createSelector(selectFinance, (finance) => finance.dashboard || [])
export const outletsSelector = (state) => state.outlets

export const relatedReportSelector = createSelector(
  (state) => state.user.menus?.related_report_menu,
  (__, name) => name,
  (relatedReportMenu, name) => relatedReportMenu?.[name] || [],
)

export const makeCashBanksResults = () =>
  createSelector(
    [bankAccountsSelector, dashboardSelector, optionsSelector, multiCurrencySelector],
    (bankAccounts, dashboard, options, multiCurrency) => (search) => {
      const {
        bank_account_sort_by: bankAccountSortBy,
        multi_currency: multiCurrencyOption,
        property_currency_symbol: propertyCurrencySymbol,
      } = options
      const currencies = multiCurrency?.currencies || []
      const isMultiCurrency = multiCurrencyOption === 1
      const searchToLowerCase = search.toLowerCase()
      // Pertama urutkan bank accounts berdasarkan pengaturan di option
      const sortedBankAccounts = _.sortBy(bankAccounts, bankAccountSortBy || 'ref_code')
      // Kemudian cari berdasarkan parameter search dan diambil dasboard id nya
      const data = sortedBankAccounts
        .filter((result) => {
          return (
            result.name?.toLowerCase()?.includes(searchToLowerCase) ||
            result.ref_code?.toLowerCase()?.includes(searchToLowerCase)
          )
        })
        .map((row) => {
          // Selanjutnya dicarikan datanya ke dasboard
          const findData = dashboard.find((item) => item.id === row.dashboard_id)
          const newRow = {
            ...row,
            currencySymbol: propertyCurrencySymbol,
            closing_balance: findData?.data?.closing_balance || 0,
            statement_balance: findData?.data?.statement_balance || 0,
            graph: findData?.data?.graph || {},
            loaded: findData?.loaded || false,
            daterange: findData?.daterange || '',
            lastCache: findData?.lastCache || 0,
            cache: findData?.cache || '',
          }
          if (isMultiCurrency && !!row.currency_id) {
            const findMultiCurrency = currencies.find((currency) => currency.id === row.currency_id)
            newRow.currencySymbol = findMultiCurrency?.symbol || propertyCurrencySymbol
          }
          return newRow
        })
      const isLoaded = _.some(data, (item) => item.loaded === true)
      const newData = { data, bankAccountSortBy, isMultiCurrency, isLoaded }

      return newData
    },
  )

export const makeSelectPermission = () => {
  const permissions = createSelector(
    [(state) => state, (state, resource) => resource, (state, recursive) => recursive],
    (state, resource, recursive) => getModulePermissions(state, resource, recursive),
  )
  return permissions
}

export const makeCheckPermission = () => {
  const granted = createSelector(
    [(state) => state, (state, permission) => permission],
    (state, permission) => checkPermission(state, permission),
  )
  return granted
}
export const loadingInitSelector = createSelector(initsSelector, (inits) => inits.loadingInit)
